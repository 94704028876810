<template>
  <div>
    <div class="card mb-5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div>
              <h4 class="mb-10">Connect a doctor with
                <b-badge variant="info">{{ agent_name }}</b-badge>
              </h4>
              <b-form @submit.prevent="searchDoctor" @reset="onReset">
                <b-input-group>
                  <b-form-input type="text" v-model="search_text"
                                placeholder="Search Phone number or name"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-primary" type="submit">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="items != []">
      <div class="card-body">
        <b-badge variant="primary" class="mt-5 mb-5">Search Text : {{ search_text }}</b-badge>
        <b-badge variant="success" class="mt-5 mb-5 ml-3">Result : {{ items.length }}</b-badge>
        <div class="row">
          <div class="col-md-12">
            <b-table
                responsive
                striped hover
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                class="mt-5">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(image)="row">
                <div>
                  <b-img :src="userPhoto(row.item.details.image)" class="user_image"
                         v-if="row.item.details.image"></b-img>
                  <img src="../../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

              <template #cell(fullname)="row">
                <div>
                  {{ row.item.details.name }} {{ row.item.details.sub_type ? '('+row.item.details.sub_type+')' : ''}}
                </div>
              </template>

              <template #cell(phone_number)="row">
                <div>
                  {{ row.item.details.phone_number }}
                </div>
              </template>

              <template #cell(nature_of_service)="row">
                <div>
                  {{ row.item.details.nature_of_service }}
                </div>
              </template>

              <template #cell(fees)="row">
                <div>
                  <p mb-0>Virtual Fee: {{ row.item.details.call_fee }}</p>
                  <p mb-0>Visit Fee: {{ row.item.details.visit_fee }}</p>
                </div>
              </template>

              <template #cell(action)="row">
                <div>
                  <b-button pill variant="success" v-b-modal.modal-lg @click="sendInfo(row.item)"
                            class="btn-circle p-5"><i class="fas fa-check"></i></b-button>
                </div>
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal
        id="modal-lg"
        title="Please provide a reason"
        @show="resetModal"
        @hidden="resetModal"
        size="lg"
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
            label="Comment"
            label-for="name-input"
            invalid-feedback="Comment required"
            :state="commentState">

          <b-form-textarea
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
              :state="commentState"
              required
          ></b-form-textarea>
        </b-form-group>


      </form>
    </b-modal>

    <!--  Modal  -->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {DoctorSearchMixin} from '../../../mixins/DoctorSearchMixin';

export default {
  name: "DoctorSearch",
  mixins: [DoctorSearchMixin],

}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>