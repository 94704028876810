import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const DoctorSearchMixin = {
    data() {
        return {
            agent_name: '',
            search_text: '',
            search_data_type: null,
            items: [],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: false
                },
                {
                    key: 'fullname',
                    label: 'Fullname',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                    sortable: true
                },
                {
                    key: 'nature_of_service',
                    label: 'Nature of service',
                    sortable: true
                },
                {
                    key: 'fees',
                    label: 'Fees',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                }
            ],
            commentState: null,
            form: {
                comment: '',
            },
            doctor_id: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Doctor search for connect with agent", route: "/doctor-search"},
        ]);
    },
    metaInfo: {
        title: 'Orko Health Ltd | Doctor Search',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    created() {
        this.getAgent();
    },
    methods: {
        getAgent() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.agent_name = response.data.data.fullname
                })
        },
        searchDoctor() {
            if (this.isNumber(this.search_text)) {
                this.search_data_type = 'phone_number';
            } else {
                this.search_data_type = 'name';
            }
            axios.get(`${process.env.VUE_APP_URL}/api/v1/search/information?api_token=${localStorage.getItem('api_token')}&type=user&search_data_type=${this.search_data_type}&platform=backoffice&searched_text=${this.search_text}`)
                .then(response => {
                    this.items = response.data.data
                })
        },

        onReset() {

        },
        isNumber(num) {
            return (typeof num == 'string' || typeof num == 'number') && !isNaN(num - 0) && num !== '';
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },

        sendInfo(item) {
            this.doctor_id = item.category_id
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.commentState = valid
            return valid
        },
        resetModal() {
            this.form= {},
            this.commentState = null
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();

            form.append("connected_to", this.$route.params.id);
            form.append("requested_to", this.doctor_id);
            form.append("status", 1);
            form.append("action_type", "active");
            form.append("module_type", "connection-request");
            form.append("comment", this.form.comment);


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/partner/agent/connection/add?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code === 400 || response.data.length == 0) {
                            this.$snotify.error(response.data.message);

                            // // setTimeout(() => {
                            //     this.$router.push('/agent-wise-doctors-list/' + this.$route.params.id);
                            // // }, 1000);

                        } else {
                            this.$bvModal.hide('modal-lg');
                            this.$snotify.success('Successfully connected');
                            // setTimeout(() => {
                                this.$router.push('/agent-wise-doctors-list/' + this.$route.params.id);
                            // }, 1000);
                        }

                    })
            })
        },

    }
}
